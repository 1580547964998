import React from "react";
import cancel1 from "../../images/svg/cancel.svg";

export default function Modal({ children, onClose, cls = "box_inner" }) {
  return (
    <div>
      <div className="custom-overlay" onClick={onClose} />
      <div className="custom-modal">
        <div className="civsav_tm_modalbox_service">
          <button type="button" className="close-modal" onClick={onClose}>
            <img src={cancel1} alt="close icon" />
          </button>
          <div className={`${cls}`}>
            <div className="description_wrap scrollable">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
