import React, { createContext, useContext, useState } from "react";
import Modal from "../components/modal";

const contextDefaultValues = {
  isOpen: false,
  toggleForm: () => {
    return;
  },
};

const FormContext = createContext(contextDefaultValues);

export function useFormContext() {
  return useContext(FormContext);
}

export default function ContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const value = {
    isOpen,
    toggleForm,
  };
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
