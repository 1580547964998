import React from "react";
// import Layout from "./src/components/layout";
import "./src/styles/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/scss/modal-video.scss";
import ContextProvider from "./src/context/Form";

export const wrapPageElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>;
};
